"use client";

import { DynamicTooltipProvider } from "@/components/ui/DynamicTooltip";
import { Toaster } from "@/components/ui/toaster";
import { NextUIProvider } from "@nextui-org/react";
import { api } from "@src/lib/api";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/navigation";
import "@src/i18n/config";
import { FC, PropsWithChildren, useEffect } from "react";
import { setNeoHost } from "@character-tech/common/src/lib/axiosConstants";
import { ClientEnv } from "@src/env/client";

const ClientProviders: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    setNeoHost(ClientEnv.NEXT_PUBLIC_NEO_HOST_BASE);
  }, []);

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="system"
      disableTransitionOnChange
      enableSystem
      enableColorScheme
    >
      <NextUIProvider navigate={router.push}>
        <Toaster />
        <DynamicTooltipProvider>{children}</DynamicTooltipProvider>
      </NextUIProvider>
    </ThemeProvider>
  );
};

export default api.withTRPC(ClientProviders) as FC<PropsWithChildren>;
