"use client";
import { Inter, Onest } from "next/font/google";

const onest = Onest({
  subsets: ["latin"],
  variable: "--font-onest",
  display: "swap",
  adjustFontFallback: false,
});

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
  display: "swap",
  adjustFontFallback: false,
});

export default function GlobalStyles() {
  return (
    <style jsx global>
      {`
        :root {
          --font-inter: ${inter.style.fontFamily};
          --font-onest: ${onest.style.fontFamily};
        }
      `}
    </style>
  );
}
