"use client";

import { createContext, useContext, useMemo, type ReactNode } from "react";
import { DefaultApi } from "@/client_autogen/api";
import { useAuth } from "../auth/AuthProvider";
import { createClient } from "@src/services/experts/client";

export const ExpertsContext = createContext<DefaultApi>(
  null as unknown as DefaultApi
);

export const useExperts = () => useContext(ExpertsContext);

export const ExpertsProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { token } = useAuth();

  const value = useMemo(() => {
    return createClient(token);
  }, [token]);

  return (
    <ExpertsContext.Provider value={value}>{children}</ExpertsContext.Provider>
  );
};
