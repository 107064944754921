import { type Locale } from "date-fns";
import {
  bg,
  cs,
  de,
  el,
  enUS,
  es,
  fi,
  fr,
  hi,
  hr,
  hu,
  id,
  it,
  ja,
  ko,
  lt,
  nl,
  pl,
  pt,
  ro,
  ru,
  sv,
  th,
  tr,
  uk,
  vi,
  zhCN,
  zhTW,
} from "date-fns/locale";
// eslint-disable-next-line no-restricted-imports
import i18n from "i18next";
import Cookie from "js-cookie";
import { initReactI18next } from "react-i18next";

function requireFromCWN(modulePath: string) {
  return require(`../../node_modules/@cwn/src/i18n/${modulePath}`);
}

const getLanguageFromQueryString = () => {
  if (typeof window === "undefined") {
    return "en";
  }

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return params.get("lng") || "en";
};

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    lng: getLanguageFromQueryString(),
    resources: {
      de: {
        translations: requireFromCWN(`de.json`),
      },
      en: {
        translations: requireFromCWN(`en.json`),
      },
      es: {
        translations: requireFromCWN(`es.json`),
      },
      fr: {
        translations: requireFromCWN(`fr.json`),
      },
      it: {
        translations: requireFromCWN(`it.json`),
      },
      ko: {
        translations: requireFromCWN(`ko.json`),
      },
      br: {
        translations: requireFromCWN(`pt_BR.json`),
      },
      pt: {
        translations: requireFromCWN(`pt.json`),
      },
      ru: {
        translations: requireFromCWN(`ru.json`),
      },
      tr: {
        translations: requireFromCWN(`tr.json`),
      },
      ja: {
        translations: requireFromCWN(`ja_JP.json`),
      },
      cn: {
        translations: requireFromCWN(`zh_CN.json`),
      },
      id: {
        translations: requireFromCWN(`id_ID.json`),
      },
      pl: {
        translations: requireFromCWN(`pl_PL.json`),
      },
      th: {
        translations: requireFromCWN(`th_TH.json`),
      },
      vi: {
        translations: requireFromCWN(`vi.json`),
      },
      bg: {
        translations: requireFromCWN(`bg_BG.json`),
      },
      cz: {
        translations: requireFromCWN(`cs_CZ.json`),
      },
      gr: {
        translations: requireFromCWN(`el_GR.json`),
      },
      fi: {
        translations: requireFromCWN(`fi_FI.json`),
      },
      hi: {
        translations: requireFromCWN(`hi_IN.json`),
      },
      hr: {
        translations: requireFromCWN(`hr_HR.json`),
      },
      hu: {
        translations: requireFromCWN(`hu_HU.json`),
      },
      lt: {
        translations: requireFromCWN(`lt_LT.json`),
      },
      nl: {
        translations: requireFromCWN(`nl_NL.json`),
      },
      ro: {
        translations: requireFromCWN(`ro_RO.json`),
      },
      se: {
        translations: requireFromCWN(`sv_SE.json`),
      },
      ua: {
        translations: requireFromCWN(`uk_UA.json`),
      },
      tw: {
        translations: requireFromCWN(`zh_Hant_TW.json`),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  })
  .catch((error: unknown) => {
    console.error(error);
  });

export const languages = [
  { value: "en", label: "English", dateFnsLocale: enUS },
  { value: "es", label: "Español", dateFnsLocale: es },
  { value: "fr", label: "Français", dateFnsLocale: fr },
  { value: "it", label: "Italiano", dateFnsLocale: it },
  { value: "pt", label: "Português", dateFnsLocale: pt },
  { value: "de", label: "Deutsch", dateFnsLocale: de },
  { value: "tr", label: "Türkçe", dateFnsLocale: tr },
  { value: "pl", label: "Polski", dateFnsLocale: pl },
  { value: "ru", label: "Русский", dateFnsLocale: ru },
  { value: "id", label: "Bahasa Indonesia", dateFnsLocale: id },
  { value: "cn", label: "中文", dateFnsLocale: zhCN },
  { value: "tw", label: "繁體中文", dateFnsLocale: zhTW },
  { value: "ja", label: "日本語", dateFnsLocale: ja },
  { value: "ko", label: "한국인", dateFnsLocale: ko },
  { value: "th", label: "ไทย", dateFnsLocale: th },
  { value: "vi", label: "Tiếng Việt", dateFnsLocale: vi },
  { value: "bg", label: "Български", dateFnsLocale: bg },
  { value: "cz", label: "Čeština", dateFnsLocale: cs },
  { value: "gr", label: "Ελληνικά", dateFnsLocale: el },
  { value: "fi", label: "Suomi", dateFnsLocale: fi },
  { value: "hi", label: "हिन्दी", dateFnsLocale: hi },
  { value: "hr", label: "Hrvatski", dateFnsLocale: hr },
  { value: "hu", label: "Magyar", dateFnsLocale: hu },
  { value: "lt", label: "Lietuvių", dateFnsLocale: lt },
  { value: "nl", label: "Nederlands", dateFnsLocale: nl },
  { value: "ro", label: "Română", dateFnsLocale: ro },
  { value: "se", label: "Svenska", dateFnsLocale: sv },
  { value: "ua", label: "Українська", dateFnsLocale: uk },
];

const languageCodes = languages.map((lang) => lang.value);

export const getLanguage = () => {
  const { resolvedLanguage } = i18n;
  return resolveLanguage(resolvedLanguage ?? "en");
};

export const getDateFnsLocale = (): Locale | undefined => {
  const lng = getLanguage();
  return languages.filter((elem) => elem.value === lng)[0]?.dateFnsLocale;
};

export const getLanguageLabel = (lang: string) =>
  languages.find((elem) => elem.value === lang)?.label;

const resolveLanguage = (language = "en") => {
  if (language.includes("zh")) {
    return language === "zh-Hans-TW" ? "tw" : "cn";
  }
  if (!languageCodes.includes(language)) {
    return "en";
  }
  return language;
};

export const setLanguage = async (languageVal: string, cookieName = "") => {
  const incomingLng = resolveLanguage(languageVal);
  if (cookieName) {
    if (!incomingLng) {
      Cookie.remove(cookieName);
    } else {
      // Max cookie expiry time is 400 days
      // https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-expires-attribute
      Cookie.set(cookieName, incomingLng, { expires: 400 });
    }
  }
  await i18n.changeLanguage(incomingLng);
};

export default i18n;
