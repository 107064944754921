/**
 * This is the client-side entrypoint for your tRPC API.
 * It's used to create the `api` object which contains the Next.js App-wrapper
 * as well as your typesafe react-query hooks.
 *
 * We also create a few inference helpers for input and output types
 */
import { httpBatchLink, loggerLink } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import superjson from "superjson";
import { QueryClient, QueryClientConfig } from "@tanstack/react-query";
import { type AppRouter } from "../server/api/root";

const QueryClientOpts: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
};

const QueryClientImpl = new QueryClient(QueryClientOpts);

// When SSR is on we must return a new QueryClient every time to avoid leaking data
export const QueryClientInstance = () =>
  typeof window === "undefined"
    ? new QueryClient(QueryClientOpts)
    : QueryClientImpl;

/**
 * A set of typesafe react-query hooks for your tRPC API
 */
// @ts-ignore seems to be a typescript bug concerning the transformer, which is simultaneously required and not required
export const api = createTRPCNext<AppRouter>({
  config(opts) {
    const { ctx } = opts;
    return {
      /**
       * Transformer used for data de-serialization from the server
       * @see https://trpc.io/docs/data-transformers
       * */
      transformer: superjson,
      queryClient: QueryClientInstance(),
      links: [
        loggerLink({
          enabled: (opts) => true,
        }),
        httpBatchLink({
          // The server needs to know your app's full url
          url: `/api/trpc`,
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/v10/header
           */
          headers() {
            if (!ctx?.req?.headers) {
              return {};
            }
            // To use SSR properly, you need to forward client headers to the server
            // This is so you can pass through things like cookies when we're server-side rendering
            return {
              cookie: ctx.req.headers.cookie,
            };
          },
        }),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs
 * @example type HelloInput = RouterInputs['example']['hello']
 * */
export type RouterInputs = inferRouterInputs<AppRouter>;
/**
 * Inference helper for outputs
 * @example type HelloOutput = RouterOutputs['example']['hello']
 * */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
